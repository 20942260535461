<tui-loader
  [inheritColor]="true"
  [overlay]="true"
  [showLoader]="(loading$ | async) ?? false"
>
  <tui-island class="min-w-[430px] p-6">
    <h2
      class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
    >
      Регистрация
    </h2>
    <div class="flex flex-col gap-y-2 my-6">
      <tui-input [formControl]="info.controls.divisionName" tuiTextfieldSize="m"
        >Компания
        <input tuiTextfield autocomplete="off" />
      </tui-input>
      <tui-input [formControl]="info.controls.roleName" tuiTextfieldSize="m"
        >Роль
        <input tuiTextfield autocomplete="off" />
      </tui-input>
      <tui-input [formControl]="form.controls.email" tuiTextfieldSize="m">
        Email
        <input tuiTextfield type="email" autocomplete="email" />
      </tui-input>
      <tui-input-password
        [formControl]="form.controls.password"
        tuiTextfieldSize="m"
        >Пароль
        <input tuiTextfield autocomplete="off" />
      </tui-input-password>
      <tui-input-password
        [formControl]="form.controls.passwordConfirm"
        tuiTextfieldSize="m"
        >Подтверждение пароля <input tuiTextfield autocomplete="off"
      /></tui-input-password>
    </div>
    <button
      (click)="register()"
      [disabled]="!form.valid"
      class="w-full"
      tuiButton
      appearance="accent"
    >
      Зарегистрироваться
    </button>
  </tui-island>
</tui-loader>
