import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://6a77c66f0dcde70982a527c71ce100e2@o4508227834216448.ingest.de.sentry.io/4508227835789392',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: String(environment.production) === 'true',
  tracesSampleRate: 0.3,
  tracePropagationTargets: ['https://customsclear.by', 'https://smuggler.pro'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
